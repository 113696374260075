<template>
    <div class="Course">
      <Course/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Course from '@/components/Course.vue'
  
  export default {
    name: 'CourseView',
    components: {
      Course
    }
  }
  </script>
  