<template>
  <div class="Course">
    <h2 class="text-center description fw-bolder">{{ description }}</h2>
    <ul class="description_1 text-start">
      <li>
        {{ session_1 }}
        <ul>
          <li>{{ session_1_1 }}</li>
          <li>{{ session_1_2 }}</li>
          <a :href="session_1_google_form" target="_blank"
            ><h3 class="btn btn-primary">報名</h3></a
          >
        </ul>
      </li>
    </ul>

    <ul class="description_1 text-start">
      <li>
        {{ session_2 }}
        <ul>
          <li>{{ session_2_1 }}</li>
          <a :href="session_2_google_form" target="_blank"
            ><h3 class="btn btn-primary">報名</h3></a
          >
        </ul>
      </li>
    </ul>

    <h3 class="text-decoration-underline">{{session_2_video_title}}</h3>

    <video autoplay loop muted playsinline>
      <source src="../assets/video/course_wing_1.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <h4 class="text-start description_2 text-decoration-underline">{{ session_2_video_1_description }}</h4>

    <video autoplay loop muted playsinline>
      <source src="../assets/video/course_yin_1.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <h4 class="text-start description_2 text-decoration-underline">{{ session_2_video_2_description }}</h4>

    <video autoplay loop muted playsinline>
      <source src="../assets/video/course_yin_2.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <h4 class="text-start description_2 text-decoration-underline">{{ session_2_video_3_description }}</h4>

    
  </div>
</template>

<script>
export default {
  name: "Course",
  data() {
    return {
      description: "由本球會主教練任教，提供以下服務：",
      regularSessionImage: require("../assets/picture/regular_session.png"),
      session_1: "恆常班",
      session_1_1: "逢星期一（4:00 p.m. - 6:00 p.m.）",
      session_1_2: "逢星期三（5:00 p.m. - 7:00 p.m.）",
      session_1_google_form:
        "https://docs.google.com/forms/d/e/1FAIpQLSeN63Ty-3XmuLs7GWBRSNxeCY1VUP5tIwBY1m26CAmVBSKaRw/viewform",
      session_2: "小班或私人",
      session_2_1: "歡迎向本會教練查詢詳情",
      session_2_google_form:
        "https://docs.google.com/forms/d/e/1FAIpQLSfE3kolXpBo6o3CnY-PrLBPVnp0Y3B5JusoXCFQEcdxioVkqg/viewform",
      session_2_video_title: "學員訓練片段",
      session_2_video_1_description: "固定球路：交替直線斜線吊球上網",
      session_2_video_2_description: "控網及跟進",
      session_2_video_3_description: "隨機三點控網前一點"
    };
  },
  methods: {},
};
</script>