<template>
  <div class="contactUs">
    <form class="form text-start fw-bolder">
      <legend>{{ description }}</legend>
      <br />
      <label>閣下的姓名或稱呼：</label><br />
      <input
        name="entry.322761900"
        type="text"
        required
        v-model="name"
        style="width: 359px"
      />
      <br />
      <label class="text-start">閣下的電郵地址：</label><br />
      <input
        name="entry.935324306"
        type="text"
        required
        v-model="email"
        style="width: 359px"
      />
      <br />
      <label>閣下想查詢的問題：</label><br />
      <textarea
        name="entry.2097268127"
        type="textarea"
        required
        v-model="question"
        rows="5"
        cols="42"
      ></textarea>
      <br />
      <div style="display:inline; visibility:hidden;">1231231231231232</div><input type="submit" style="display:inline;" value="提交" class="btn btn-primary lg" @click="submitGoogleForm" />
    </form>
    <h2 class="text-center description fw-bolder"></h2>
    <h2 class="text-center description fw-bolder"></h2>
    <h2 class="text-center description fw-bolder"></h2>
    <h2 class="text-center description fw-bolder"></h2>

  </div>
  

</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      description:
        "如有任何有關本會活動的查詢，可填寫以下表格，本會將盡快回覆。",
      google_form_action:
        "https://docs.google.com/forms/d/1C-MvOC9Cnc3_kgp5uKIbBTi__PE-ydOqww2HOvKMzME/formResponse?",
      name: null,
      email: null,
      question: null,
      nameEntry: "322761900",
      emailEntry: "935324306",
      questionEntry: "2097268127",
      popUpMessage: "你的查詢已被成功提交。",
    };
  },

  methods: {
    async submitGoogleForm() {
      if (this.name != null && this.email != null && this.question != null) {
        const prefilledLink =
          this.google_form_action +
          "usp=pp_url&entry." +
          this.nameEntry +
          "=" +
          this.name +
          "&entry." +
          this.emailEntry +
          "=" +
          this.email +
          "&entry." +
          this.questionEntry +
          "=" +
          this.question +
          "&submit=submit";

        try {
          const res = await fetch(prefilledLink).then(window.alert(this.popUpMessage));
          
        } catch {
        } finally {

        }

        

      }
    },
  },
};
</script>