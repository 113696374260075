<template>
    <div class="Coach">

    <img id="coach_both_image" :src="coach_both_image">
    <h2 class="description_1 text-start fw-bolder">張子穎教練</h2>
    <ul class="description_1 text-start">
      <li><h6>香港羽毛球會註冊教練</h6></li>
      <li><h6>2024全港運動會黃大仙區雙打代表</h6></li>
      <li><h6>合作機構：
        <ul>
          <h6><li>耀中國際學校</li></h6>
          <h6><li>聖芳濟各英文小學</li></h6>
        </ul>
      </h6></li>
      <li><h6>近年主要獎項:
        <ul>
          <h6><li>2023全港錦標賽初級組男子雙打季軍</li></h6>
          <h6><li>2022全港羽毛球雙打聯賽D組亞軍</li></h6>
          <h6><li>2022全港會長盃團體初級組亞軍</li></h6>
          <h6><li>2022黃大仙區羽毛球分齡賽男子單打冠軍</li></h6>
          <h6><li>2022黃大仙區羽毛球分齡賽男子雙打冠軍</li></h6>
          <h6><li>2021全港錦標賽初級組男子雙打亞軍</li></h6>
          <h6><li>2020敬發盃男子單打公開亞軍</li></h6>
          <h6><li>2020敬發盃男子雙打分齡亞軍</li></h6>
          <h6><li>2020敬發盃男子單打公開亞軍</li></h6>
          <h6><li>2019敬發盃男子雙打分齡季軍</li></h6>
          <h6><li>2019敬發盃混合雙打分齡亞軍</li></h6>
          <h6><li>2019仁愛堂男子雙打公開組季軍</li></h6>
          <h6><li>2019黃大仙區羽毛球分齡賽男子雙打亞軍</li></h6>
          <h6><li>2018仁愛堂男子單打公開組季軍</li></h6>
          <h6><li>2018深水埗區羽毛球分齡賽男子單打冠軍</li></h6>
          <h6><li>2018黃大仙區羽毛球分齡賽男子雙打冠軍</li></h6>
          <h6><li>2017敬發盃男子雙打公開組季軍</li></h6>
          <h6><li>2017中西區羽毛球分齡賽男子單打冠軍</li></h6>

        </ul>
      </h6></li>

    </ul>

    <h2 class="description_1 text-start fw-bolder">雷倬賢教練</h2>
    <ul class="description_1 text-start">
      <li><h6>八年教學經驗</h6></li>
      <li><h6>2024全港運動會黃大仙區單打代表</h6></li>
      <h6><li>第六屆、第七屆全港運動會黃大仙區單打代表</li></h6>
      <li><h6>合作機構：
        <ul>
          <h6><li>基福小學</li></h6>
          <h6><li>耀中國際學校</li></h6>
          <h6><li>禾輋社區中心</li></h6>
          <h6><li>黃大仙利民會</li></h6>
        </ul>
      </h6></li>
      <li><h6>近年主要獎項:
        <ul>
          <h6><li>2021黃大仙區羽毛球分齡賽單打亞軍</li></h6>
          <h6><li>2021北區羽毛球分齡賽單打亞軍</li></h6>
          <h6><li>2021深水埗區羽毛球分齡賽單打亞軍</li></h6>
          <h6><li>2019黃大仙區羽毛球分齡賽單打冠軍</li></h6>
          <h6><li>2019黃大仙區羽毛球分齡賽雙打殿軍</li></h6>
          <h6><li>2019油尖旺區羽毛球分齡賽單打冠軍</li></h6>
          <h6><li>2019馬鞍山盃公開組單打亞軍</li></h6>
          <h6><li>2018黃大仙區羽毛球分齡賽單打冠軍</li></h6>
          <h6><li>2018北區羽毛球分齡賽單打冠軍</li></h6>
          <h6><li>2018馬鞍山盃公開組單打冠軍</li></h6>
          <h6><li>2018台灣羽毛球分級賽3.0單打亞軍</li></h6>


        </ul>
      </h6></li>

    </ul>
    
  

    </div>
  </template>


<script>
export default {
  name: 'Coach',
  data() {
    return {
        coach_both_image: require("../assets/picture/coach_team.png"),

    }
  },
  methods: {
    
  }
}
</script>