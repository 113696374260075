<template>
    <div class="Notice">

      <Notice/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Notice from '@/components/Notice.vue'
  
  export default {
    name: 'NoticeView',
    components: {
        Notice
    }
  }
  </script>
  