<template>
    <div class="Event">
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder">{{description}}</h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
    </div>
  </template>

<script>
export default {
  name: 'Event',
  data() {
    return {
        description: "敬請期待"
    }
  },
  methods: {
 
  }
}
</script>