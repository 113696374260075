<template>
    <div class="contactUs">

      <ContactUs />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import ContactUs from '@/components/ContactUs.vue'
  
  export default {
    name: 'ContactUsView',
    components: {
      ContactUs
    }
  }
  </script>
  