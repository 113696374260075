<template>
    <div class="SocialMedia">

      <SocialMedia/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import SocialMedia from '@/components/SocialMedia.vue'
  
  export default {
    name: 'SocialMediaView',
    components: {
      SocialMedia
    }
  }
  </script>
  