<template>
  <div class="home">
    <Introduction/>
    <Home></Home>
  </div>
</template>

<script>
import Introduction from '@/components/Introduction.vue'

export default {
  name: 'HomeView',
  components: {
    Introduction
  }
}
</script>
