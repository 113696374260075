<template>
    <div class="News">

      <News/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import News from '@/components/News.vue'
  
  export default {
    name: 'NewsView',
    components: {
        News
    }
  }
  </script>
  