<template>
    <div class="SocailMedia">
        <h2 class="text-center description fw-bolder">{{ social_media_1_description }}</h2>
        <a :href="social_media_1_lnk" target="_blank"><img class="social_meida_icon" :src="social_media_1_image"></a>
        <h2 class="text-center description fw-bolder">{{ social_media_2_description }}</h2>
        <a :href="social_media_2_lnk" target="_blank"><img class="social_meida_icon" :src="social_media_2_image" style="width:170px;"></a>
        <h2 class="text-center description fw-bolder">{{ social_media_3_description }}</h2>
        <a :href="social_media_3_lnk" target="_blank"><img class="social_meida_icon" :src="social_media_3_image"  style="width:160px;"></a>
    </div>

 </template>

<script>

export default {
  name: 'SocialMedia',
  data() {
    return {
        social_media_1_description: "Instagram",
        social_media_1_image: require("../assets/picture/ig_icon.png"),
        social_media_1_lnk: "https://www.instagram.com/po.kwong_badminton/",
        social_media_2_description: "Facebook",
        social_media_2_image: require("../assets/picture/fb_icon.png"),
        social_media_2_lnk: "https://www.facebook.com/profile.php?id=61553622913370",
        social_media_3_description: "Email",
        social_media_3_image: require("../assets/picture/email_icon.png"),
        social_media_3_lnk: "mailto: pokwongbc@gmail.com"

    }
  },
  methods: {
 
  }
}
</script>