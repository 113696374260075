<template>
    <div class="Notice">
        <h2 class="text-center description fw-bolder text-decoration-underline">{{description}}</h2>
        <h4  class="text-start description fw-bolder text-decoration-underline">{{ notice_1 }}</h4>
        <h4  class="text-start description fw-bolder text-decoration-underline">{{ notice_2 }}</h4>
        <h4  class="text-start description fw-bolder text-decoration-underline">{{ notice_3 }}</h4>
        <h4  class="text-start description fw-bolder text-decoration-underline">{{ notice_4 }}</h4>
        <h4  class="text-start description fw-bolder text-decoration-underline">{{ notice_5 }}</h4>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>
        <h2 class="text-center description fw-bolder"></h2>


    </div>
  </template>

<script>
export default {
  name: 'Notice',
  data() {
    return {
        description: "注意事項：",
        notice_1: "1. 課堂時段和地點有機會因應租場情況而有所變動，教練會預早一星期通知學員確實時段及地點。",
        notice_2: "2. 按月收費，學員必須準時繳費以確保課堂安排。",
        notice_3: "3. 如遇上惡劣天氣情況(如8號或以上暴風信號及及黑色暴雨等)，本會將另外安排補課時段並通知學員。",
        notice_4: "4. 學員必須穿著適當的運動服裝，並自備羽毛球拍。",
        notice_5: "5. 本會保留更改使用條款及細則之權利，而不會另行通知，如有任何爭議，決定權由本會所擁有。"
    }
  },
  methods: {
 
  }
}
</script>