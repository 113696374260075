<template>
    <div class="Introduction">
        <img id="homepageImage" :src="homepageImg">
        <h2 class="text-center description fw-bolder">{{description}}</h2>
        <img :src="regularSessionImage">
    </div>
  </template>

<script>
export default {
  name: 'Introduction',
  data() {
    return {
        description: "蒲光羽毛球會是札根於黃大仙區的年輕羽毛球會，教練團隊由熱衷羽毛球運動的地區代表組成，除了致力於青少年羽毛球培訓，同時也提供適合成人的興趣班課程。",
        regularSessionImage: require("../assets/picture/regular_session.png"),
        homepageImg:  require("../assets/picture/coach_both.jpeg")
    }
  },
  methods: {
 
  }
}
</script>