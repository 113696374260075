import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CoachView from '../views/CoachView.vue'
import CourseView from '../views/CourseView.vue'
import EventView from '../views/EventView.vue'
import NewsView from '../views/NewsView.vue'
import HighlightView from '../views/HighlightView.vue'
import SocialMediaView from '../views/SocialMediaView.vue'
import NoticeView from '../views/NoticeView.vue'
import ContactUsView from '../views/ContactUsView.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/coach',
    name: 'coach',
    component: CoachView
  },
  {
    path: '/course',
    name: 'course',
    component: CourseView
  },
  {
    path: '/event',
    name: 'event',
    component: EventView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/highlight',
    name: 'highlight',
    component: HighlightView
  },
  {
    path: '/socialMedia',
    name: 'socialMedia',
    component: SocialMediaView
  },
  {
    path: '/notice',
    name: 'notice',
    component: NoticeView
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to);
  document.title = "蒲光羽毛球會";
  next();
});


export default router
