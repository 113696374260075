<template>
    <div class="highlight">

      <Highlight/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Highlight from '@/components/Highlight.vue'
  
  export default {
    name: 'HighlightView',
    components: {
      Highlight
    }
  }
  </script>
  