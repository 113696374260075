<template>
    <div class="event">

      <Event/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Event from '@/components/Event.vue'
  
  export default {
    name: 'EventView',
    components: {
      Event
    }
  }
  </script>
  