<template>

  <div class="container text-white">
    <div class="banner_padding"></div>
    <span>
      <img class="banner" id="logo" src="./assets/picture/logo_1.png" style="width:20%;">
      <h1  class="fw-bolder banner">蒲光羽毛球會</h1>
    </span>
    <div class="banner_padding"></div>
  <nav class="navbar navbar-default">
    <router-link to="/" class="btn btn-warning btn-lg" style=""><strong>球會介紹</strong></router-link>
    <router-link to="/coach"  class="btn btn-warning btn-lg"><strong>駐會教練</strong></router-link>
    <router-link to="/course"  class="btn btn-warning btn-lg"><strong>課堂詳情</strong></router-link>
    <router-link to="/event"  class="btn btn-warning btn-lg"><strong>球會活動</strong></router-link>
    <router-link to="/news"  class="btn btn-warning btn-lg"><strong>最新動態</strong></router-link>
    <router-link to="/highlight"  class="btn btn-warning btn-lg"><strong>比賽花絮</strong></router-link>
    <router-link to="/socialMedia"  class="btn btn-warning btn-lg"><strong>社群媒體</strong></router-link>
    <router-link to="/Notice"  class="btn btn-warning btn-lg"><strong>注意事項</strong></router-link>
    <router-link to="/contactUs"  class="btn btn-warning btn-lg"><strong>聯絡我們</strong></router-link>
  </nav>

  <div class="banner_padding"></div>
  <router-view/>

  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 5px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: green;
}

img {
  width: 100%;
  object-fit: cover;
  margin: auto;
}

video {
  width: 100%;
  object-fit: cover;
  margin: auto;
}

.description {
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;
  margin: auto;
}

.description_1 {
  padding-top: 1%;
  width: 100%;
  margin: auto;
}

.description_2 {
  padding-bottom: 2%;
  width: 100%;
  margin: auto;
}

.ig_post{
  margin: auto;
  display:table;
  width: 40%;
}

#logo{
  width: 10%;
}

.banner{
  display: inline;
  font-family: 'SimSun';
  font-size: 40px;
  vertical-align: middle;
}

body{
  background-image: url("./assets/picture/background.png");
  background-size: contain;
}

.banner_padding{
  padding: 1%;
}

.social_meida_icon{
  width: 150px;
}

.form{
  margin: auto;
  width: 350px;
}

@media (min-width: 1000px) {
  img{ width: 60%; }
  .description {width: 60%; }
  .description_1 {width: 60%; }
  .description_2 {width: 60%; }
  video{ width: 60%; }
}






</style>
